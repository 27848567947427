import React from "react";
import { generateId, registerComponent } from "@/utils";
import {
    classProp,
    disabledProp,
    iconProps,
    marginProp,
    radiusProp,
    sizeProp,
    snakeCasePropDescriptors,
    styleProp,
    usePlaceholder,
    useVisibleProp,
    visibleProp,
} from "@/utils/props";
import {
    useChainIconProps,
    useChainMarginStyles,
    useChainProps,
    useChainStyle,
    useChainStyleAndClassName,
} from "@/utils/props/chain";
import { ReactComponentDefinition, useActions } from "@anvil-works/anvil-react";
import {
    DropZone,
    inDesigner,
    useDesignerInteractionRef,
    useInlineEditSectionRef,
    useInteraction,
    useRegionInteractionRef,
    useSectionRef,
} from "@anvil-works/anvil-react/designer";
import { Tabs } from "@mantine/core";

/*
Tabs component props
activateTabWithKeyboard	boolean	Determines whether tab should be activated with arrow key press, true by default
allowTabDeactivation	boolean	Determines whether tab can be deactivated, false by default
autoContrast	boolean	Determines whether active item text color should depend on background-color of the indicator. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast. Only applicable when variant="pills"
children	React.ReactNode	Tabs content
color	MantineColor	Changes colors of Tabs.Tab components when variant is pills or default, does nothing for other variants
defaultValue	string | null	Default value for uncontrolled component
id	string	Base id, used to generate ids to connect labels with controls, generated randomly by default
inverted	boolean	Determines whether tabs should have inverted styles, false by default
keepMounted	boolean	If set to false, Tabs.Panel content will be unmounted when the associated tab is not active, true by default
loop	boolean	Determines whether arrow key presses should loop though items (first to last and last to first), true by default
onChange	((value: string | null) => void)	Called when value changes
orientation	"horizontal" | "vertical"	Tabs orientation, 'horizontal' by default
placement	"left" | "right"	Tabs.List placement relative to Tabs.Panel, applicable only when orientation="vertical", 'left' by default
radius	MantineRadius | number	Key of theme.radius or any valid CSS value to set border-radius, theme.defaultRadius by default
value	string | null	Value for controlled component

TabsList component props
Name	Type	Description
children *	React.ReactNode	Tabs.Tab components
grow	boolean	Determines whether tabs should take all available space, false by default
justify	JustifyContent	Tabs alignment, flex-start by default

TabsTab component props
Name	Type	Description
children	React.ReactNode	Tab label
color	MantineColor	Key of theme.colors or any valid CSS color, controls control color based on variant
leftSection	React.ReactNode	Content displayed on the left side of the label, for example, icon
rightSection	React.ReactNode	Content displayed on the right side of the label, for example, icon
size	string | number	Size passed from parent component, sets data-size if value is not number like
value *	string	Value of associated panel

*/

interface TabSection {
    id: string;
    value: string;
    label?: string;
    color?: string;
    size?: string | number;
    iconRight?: string;
    iconLeft?: string;
    disabled?: boolean;
}

const tabSectionProperties = [
    {
        name: "label",
        type: "string",
        description: "Tab label",
        important: true,
    },
    {
        name: "color",
        type: "color",
        group: "appearance",
        description: "Key of theme.colors or any valid CSS color, controls control color based on variant",
    },
    {
        name: "value",
        type: "string",
        description: "Value of associated panel",
        important: true,
    },
    ...iconProps,
    sizeProp,
    disabledProp,
    classProp,
    styleProp,
] satisfies ReactComponentDefinition["properties"];

const {
    snakeProperties: tabSectionSnakeProperties,
    camelCaseProps: camelCaseTabSectionProps,
    snakeCaseProps: snakeCaseTabSectionProps,
} = snakeCasePropDescriptors(tabSectionProperties);

interface TabsTabProps extends TabSection {
    idx: number;
    tabs: TabSection[];
}

function TabsTab({ id: sectionId, tabs, idx, ...props }: TabsTabProps) {
    const { setProperty } = useActions();

    const value = props.value;
    const cleanProps = useChainProps(camelCaseTabSectionProps(props), [useChainStyle, useChainIconProps]);
    const [placeholder, inlineEditOptions] = usePlaceholder(props.label, { defaultValue: props.value });

    useInteraction({
        sectionId,
        type: "whole_component",
        title: "Delete tab",
        icon: "delete",
        callbacks: {
            execute() {
                setProperty(
                    "tabs",
                    tabs.filter((tab) => sectionId !== tab.id)
                );
            },
        },
    });

    let ref = useDesignerInteractionRef<HTMLButtonElement>("dblclick", () => {
        setProperty("value", value);
    });

    ref = useRegionInteractionRef<HTMLButtonElement>(
        () => {
            setProperty("value", value);
        },
        ref,
        { sensitivity: 2 }
    );

    useInteraction(
        idx !== 0 && {
            sectionId,
            type: "whole_component",
            title: "Move left",
            icon: "arrow_left",
            callbacks: {
                execute() {
                    const [tab] = tabs.splice(idx, 1);
                    tabs.splice(idx - 1, 0, tab);
                    setProperty("tabs", tabs);
                },
            },
        }
    );

    useInteraction(
        idx !== tabs.length - 1 && {
            sectionId,
            type: "whole_component",
            title: "Move right",
            icon: "arrow_right",
            callbacks: {
                execute() {
                    const [tab] = tabs.splice(idx, 1);
                    tabs.splice(idx + 1, 0, tab);
                    setProperty("tabs", tabs);
                },
            },
        }
    );

    const tabRef = useSectionRef<HTMLButtonElement>(
        {
            id: sectionId,
            title: value,
            sectionProperties: tabSectionSnakeProperties,
            sectionPropertyValues: snakeCaseTabSectionProps(props),
            setSectionPropertyValues: (updates) => {
                const tab = tabs.find(({ id }) => id === sectionId);
                if (tab) {
                    Object.assign(tab, updates);
                    setProperty("tabs", tabs);
                }
            },
        },
        ref
    );

    return (
        <Tabs.Tab value={value} ref={tabRef} {...cleanProps}>
            <span ref={useInlineEditSectionRef(sectionId, "label", null, inlineEditOptions)}>
                {props.label || placeholder}
            </span>
        </Tabs.Tab>
    );
}

const TabListComponentProperties: ReactComponentDefinition["properties"] = [
    {
        name: "grow",
        type: "boolean",
        description: "Determines whether tabs should take all available space, false by default",
        defaultValue: false,
        group: "tab list",
    },
    {
        name: "justify",
        type: "enum",
        options: ["flex-start", "center", "flex-end", "space-between", "space-around"],
        description: "Tabs alignment, flex-start by default",
        defaultValue: "flex-start",
        group: "tab list",
    },
];

const EMPTY_TABS: TabSection[] = [];

registerComponent({
    name: "Tabs",
    container: true,
    properties: [
        {
            name: "variant",
            type: "enum",
            options: ["default", "outline", "pills"],
            defaultValue: "default",
            important: true,
        },
        { name: "tabs", type: "object", description: "Tabs", important: true },
        ...TabListComponentProperties,
        {
            name: "activateTabWithKeyboard",
            type: "boolean",
            description: "Determines whether tab should be activated with arrow key press, true by default",
            defaultValue: true,
            group: "behavior",
        },
        {
            name: "allowTabDeactivation",
            type: "boolean",
            description: "Determines whether tab can be deactivated, false by default",
            defaultValue: false,
            group: "behavior",
        },
        {
            name: "autoContrast",
            type: "boolean",
            description:
                'Determines whether active item text color should depend on background-color of the indicator. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast. Only applicable when variant="pills"',
            defaultValue: false,
            group: "appearance",
        },
        {
            name: "color",
            type: "color",
            description:
                "Changes colors of Tabs.Tab components when variant is pills or default, does nothing for other variants",
            group: "appearance",
        },
        {
            name: "id",
            type: "string",
            description: "Base id, used to generate ids to connect labels with controls, generated randomly by default",
            defaultValue: "",
            group: "id",
        },
        {
            name: "inverted",
            type: "boolean",
            description: "Determines whether tabs should have inverted styles, false by default",
            defaultValue: false,
            group: "layout",
        },
        {
            name: "keepMounted",
            type: "boolean",
            description:
                "If set to false, Tabs.Panel content will be unmounted when the associated tab is not active, true by default",
            defaultValue: true,
            group: "behavior",
        },
        {
            name: "loop",
            type: "boolean",
            description:
                "Determines whether arrow key presses should loop though items (first to last and last to first), true by default",
            defaultValue: true,
            group: "behavior",
        },
        {
            name: "orientation",
            type: "enum",
            options: ["horizontal", "vertical"],
            description:
                "Determines whether tabs should be displayed horizontally or vertically, horizontal by default",
            defaultValue: "horizontal",
            group: "layout",
        },
        { name: "placement", type: "enum", options: ["left", "right"], defaultValue: "left", group: "layout" },
        radiusProp,
        {
            name: "value",
            type: "string",
            description: "Controlled component value",
            important: true,
            priority: 10,
            defaultBindingProp: true,
            supportsWriteback: true,
        },
        visibleProp,
        styleProp,
        classProp,
        marginProp,
    ],
    layoutProperties: [
        {
            name: "tab",
            type: "string",
            description: "The tab this child belongs to",
        },
    ],
    events: [{ name: "change", defaultEvent: true }],
    autoDropZoneProps: false,
    component(
        { properties: { visible, value, tabs, grow, justify, ...props }, children, childrenWithLayoutProperties },
        ref
    ) {
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        tabs ||= EMPTY_TABS;

        props = useChainProps(props, [
            useChainStyleAndClassName({ overrides: inDesigner ? { minHeight: 45 } : null }),
            useChainMarginStyles,
        ]);

        const onChange = async (value: string | null) => {
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change");
            }
        };

        ref = useVisibleProp(ref, visible);

        useInteraction({
            type: "whole_component",
            title: "Add tab",
            icon: "add",
            callbacks: {
                execute() {
                    setProperty("tabs", [...tabs, { id: generateId(), value: `tab-${tabs.length + 1}`, label: `` }]);
                },
            },
        });

        const tabPanels = tabs.map(({ id, value }: { id: string; value: string }) => {
            const children = childrenWithLayoutProperties.filter(({ layoutProperties: { tab } }) => tab === id);

            return (
                <Tabs.Panel key={id} value={value}>
                    <DropZone
                        maxChildIdx={children.length > 0 ? children[0].childIdx : undefined}
                        layoutProperties={{ tab: id }}
                    />
                    {children.map(({ child, childIdx, key }) => (
                        <React.Fragment key={key}>
                            {child}
                            <DropZone
                                minChildIdx={childIdx + 1}
                                maxChildIdx={childIdx + 1}
                                layoutProperties={{ tab: id }}
                            />
                        </React.Fragment>
                    ))}
                </Tabs.Panel>
            );
        });

        const tabList = (
            <Tabs.List grow={grow} justify={justify}>
                {(tabs as TabSection[]).map((props, idx) => (
                    <TabsTab key={props.id} idx={idx} tabs={tabs} {...props} />
                ))}
            </Tabs.List>
        );

        return (
            <Tabs value={value ?? ""} onChange={onChange} ref={ref} {...props}>
                {props.inverted ? [tabPanels, tabList] : [tabList, tabPanels]}
            </Tabs>
        );
    },
});
