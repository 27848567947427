import React, { createContext, useContext, useRef, useState } from "react";
import { registerComponent } from "@/utils";
import { useMergeRefs } from "@/utils/merge-refs";
import {
    autoContrast,
    colorProp,
    disabledProp,
    inputInteractionProps,
    inputPartProps,
    marginProp,
    radiusProp,
    sizeProp,
    styleProps,
    useInlineEditInputParts,
    useMarginStyles,
    useStyleObject,
    useVisibleProp,
    variant,
    visibleProp,
} from "@/utils/props";
import useEvent from "@/utils/use-event";
import { useActions, useMethodHandler } from "@anvil-works/anvil-react";
import { inDesigner, useRegionInteractionRef } from "@anvil-works/anvil-react/designer";
import { Checkbox, CheckboxGroup } from "@mantine/core";
import { use } from "marked";

registerComponent({
    name: "Checkbox",
    events: [{ name: "change", defaultEvent: true }],
    methods: [{ name: "focus" }],
    properties: [
        autoContrast,
        colorProp,
        ...inputPartProps,
        variant(["filled", "outline"], "filled"),
        {
            name: "indeterminate",
            type: "boolean",
            group: "interaction",
            description: "Indeterminate state of the checkbox. If set, checked prop is ignored.",
        },
        disabledProp,
        {
            name: "labelPosition",
            type: "enum",
            options: ["left", "right"],
            // important: true,
            group: "input",
            defaultValue: "right",
            description: "Position of the label relative to the input, 'right' by default",
        },
        radiusProp,
        sizeProp,
        {
            name: "checked",
            type: "boolean",
            defaultValue: false,
            description: "",
            important: true,
            priority: 10,
            supportsWriteback: true,
            defaultBindingProp: true,
            designerHint: "toggle",
        },
        {
            name: "value",
            type: "string",
            description: "Used by the Checkbox group",
            group: "input",
        },
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    component({ properties: { checked, value, visible, style, className, margin, ...props } }, ref) {
        const { setProperty, raiseEvent, triggerWriteBack } = useActions();
        const ctx = useCheckboxGroupContext();
        checked ??= false;

        // if the child changes checked
        // then we need to fire
        const [prevChecked, setPrevChecked] = useState(checked);

        const updateChecked = async (checked: boolean) => {
            setPrevChecked(checked);
            setProperty("checked", checked);
            try {
                await triggerWriteBack("checked", checked);
            } finally {
                raiseEvent("change");
            }
        };
        const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
            updateChecked(e.target.checked);
        };

        if (ctx) {
            const groupChecked = ctx.value.includes(value);
            if (groupChecked !== checked) {
                // console.log("%cgroupChecked", "color: hotpink;", value, groupChecked, checked, prevChecked);
                // then our group has changed our value and our group wins
                if (prevChecked === checked) {
                    queueMicrotask(() => {
                        updateChecked(groupChecked);
                    });
                } else {
                    ctx.changedTargetsRef.current.push({ value, checked });
                    // we have changed our own checked value tell context to update
                    queueMicrotask(() => {
                        setPrevChecked(checked);
                        ctx.forceChange();
                    });
                }
            } else if (prevChecked !== checked) {
                setPrevChecked(checked);
            }
        } else if (prevChecked !== checked) {
            setPrevChecked(checked);
        }

        ref = useVisibleProp(ref, visible);
        const inputRef = useRef<HTMLInputElement>(null);
        const regionRef = useRegionInteractionRef<HTMLInputElement>(() => {
            setProperty("checked", !checked);
        });
        const mergedRefs = useMergeRefs(regionRef, inputRef);
        useMethodHandler("focus", () => inputRef.current?.focus());

        const inputParts = useInlineEditInputParts(props);

        return (
            <Checkbox
                styles={{ root: useStyleObject(style) }}
                classNames={{ root: className }}
                checked={!!checked}
                value={value}
                onChange={onChange}
                rootRef={ref}
                ref={mergedRefs}
                {...props}
                {...inputParts}
                {...useMarginStyles(margin)}
            />
        );
    },
});

const EMPTY_ARRAY: string[] = [];

interface CheckboxGroupContextValue {
    value: string[];
    changedTargetsRef: React.MutableRefObject<Array<{ value: string; checked: boolean }>>;
    forceChange: () => void;
}

const CheckboxGroupContext = createContext<CheckboxGroupContextValue | null>(null);
export const CheckboxGroupProvider = CheckboxGroupContext.Provider;
export const useCheckboxGroupContext = () => useContext(CheckboxGroupContext);

registerComponent({
    name: "CheckboxGroup",
    container: true,
    events: [{ name: "change", defaultEvent: true }],
    properties: [
        ...inputPartProps,
        ...inputInteractionProps,
        sizeProp,
        {
            name: "value",
            type: "text[]",
            description: "Controlled component value",
            defaultValue: [],
            important: true,
            priority: 10,
            defaultBindingProp: true,
            supportsWriteback: true,
        },
        visibleProp,
        ...styleProps,
        marginProp,
    ],
    layoutProperties: [],
    component({ children, properties: { visible, value, style, className, margin, ...props } }, ref) {
        ref = useVisibleProp(ref, visible);
        value ??= EMPTY_ARRAY;
        const groupRef = useRef<HTMLDivElement>(null);
        const { triggerWriteBack, setProperty, raiseEvent } = useActions();

        const onChange = useEvent(async (value: string[]) => {
            setProperty("value", value);
            try {
                await triggerWriteBack("value", value);
            } finally {
                raiseEvent("change");
            }
        });

        const styleObject = useStyleObject(style);
        const marginStyles = useMarginStyles(margin);
        if (inDesigner) {
            styleObject.minHeight = 20;
        }
        const inputParts = useInlineEditInputParts(props);
        const changedTargetsRef = useRef<Array<{ value: string; checked: boolean }>>([]);

        return (
            <CheckboxGroup
                value={value ?? EMPTY_ARRAY}
                onChange={onChange}
                ref={useMergeRefs(ref, groupRef)}
                styles={{ root: styleObject }}
                classNames={{ root: className }}
                {...props}
                {...inputParts}
                {...marginStyles}>
                <CheckboxGroupContext.Provider
                    value={{
                        value,
                        changedTargetsRef,
                        forceChange() {
                            const targets = changedTargetsRef.current;
                            changedTargetsRef.current = [];
                            if (targets.length === 0) {
                                return;
                            }
                            const newValue = (value as string[]).filter((x) => !targets.some((t) => t.value === x));
                            const checkedTargets = new Set<string>();
                            targets.forEach((t) => {
                                if (t.checked) {
                                    checkedTargets.add(t.value);
                                } else {
                                    checkedTargets.delete(t.value);
                                }
                            });
                            newValue.push(...checkedTargets);
                            onChange(newValue);
                        },
                    }}>
                    {children}
                </CheckboxGroupContext.Provider>
            </CheckboxGroup>
        );
    },
});
